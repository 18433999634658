import React, { useContext, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useForm } from "react-hook-form";
import { Context } from "../cache";
import MyButton from "../components/MyButton";
import { useAuth } from "../hooks/useAuth";
import { parseError, useNetworking } from "../utils/networking";

const popover = {
  position: "absolute",
  zIndex: "2",
};
const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

const BasicInfo = () => {
  const [state] = useContext(Context);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const { handleSubmit, register, getValues, reset } = useForm();
  const { firestore, user, getNextPage } = useAuth();
  const networking = useNetworking();

  const [brandColor, setBrandColor] = useState(
    state.podcast?.brandColor || "#000000"
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset({
      ...getValues(),
    });
    setBrandColor(state.podcast?.brandColor || "#000000");
  }, [state.podcast]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      console.log("form submiitted");

      await networking.post("/podcasts/check-slug", {
        id: state.podcast.id,
        slug: data.slug,
      });
      const podRef = firestore.collection("podcasts").doc(state.podcast.id);
      await podRef.update({
        ...data,
        brandColor,
      });
      await getNextPage(user);
    } catch (error) {
      window.alert(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleColorChangeComplete = (color) => {
    setBrandColor(color.hex);
  };

  console.log("redraw", getValues("programMilestons"));
  return (
    <form
      className="bg-white rounded-lg shadow overflow-hidden px-8 py-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label className="block text-sm font-medium text-gray-700">
            Webpage URL
          </label>
          <div className="mt-1 flex flex-row items-center">
            <div className="max-w-lg flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                https://refer.fm/
              </span>
              <input
                spellCheck={false}
                type="text"
                defaultValue={state.podcast.slug}
                {...register("slug")}
                className="flex-1 slug w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
              />
            </div>
          </div>
          <p className="text-xs text-red-900 mt-1">
            Changing this will break any existing referral links.
          </p>
        </div>

        <div className="sm:col-span-6">
          <label className="block text-sm font-medium text-gray-700">
            Header text
          </label>
          <div className="mt-1">
            <input
              type="text"
              defaultValue={state.podcast.customHeading}
              {...register("customHeading")}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">
            Podcast name
          </label>
          <div className="mt-1">
            <input
              required
              defaultValue={state.podcast.name}
              {...register("name")}
              type="text"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">
            Podcast subtitle
          </label>
          <div className="mt-1">
            <input
              type="text"
              required
              defaultValue={state.podcast.description || ""}
              {...register("description")}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="about"
            className="block text-sm font-medium text-gray-700"
          >
            Podcast description
          </label>
          <div className="mt-1">
            <textarea
              {...register("longDescription")}
              rows={3}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              defaultValue={state.podcast.longDescription || ""}
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">
            Spotify ID
          </label>
          <div className="mt-1">
            <input
              type="text"
              defaultValue={state.podcast.spotifyId || ""}
              {...register("spotifyId")}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <p className="text-xs text-gray-500 mt-1">
            https://open.spotify.com/show/
            <b>6ULQ0ewYf5zmsDgBchlkr9</b>
          </p>
        </div>

        <div className="sm:col-span-3">
          <label className="block text-sm font-medium text-gray-700">
            Brand color
          </label>
          <div className="mt-1 pb-2">
            <div
              onClick={() => {
                setColorPickerOpen((currentlyOpen) => {
                  console.log("sett color picker open: ", !currentlyOpen);
                  return !currentlyOpen;
                });
              }}
              className="flex flex-row"
            >
              <div
                className="w-12 flex-grow rounded-md"
                style={{ backgroundColor: brandColor }}
              ></div>
              <input
                type="text"
                disabled={true}
                defaultValue={brandColor}
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            {colorPickerOpen ? (
              <div style={popover}>
                <div
                  style={cover}
                  onClick={() => {
                    setColorPickerOpen(false);
                  }}
                />
                <SketchPicker
                  color={brandColor}
                  disableAlpha={true}
                  onChange={handleColorChangeComplete}
                  onChangeComplete={handleColorChangeComplete}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-center">
          <MyButton loading={loading} type="submit" text="Save" />
        </div>
      </div>
    </form>
  );
};

export default BasicInfo;
