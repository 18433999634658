import React, { useReducer } from "react";
import Context from "./context";
import Reducer from "./reducer";

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, {
    tempPodcast: null,
    podcast: null,
    user: null,
  });
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export default Store;
