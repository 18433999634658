import { PlusIcon as PlusIconOutline } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Context } from "../cache";
import MyButton from "../components/MyButton";
import { useAuth } from "../hooks/useAuth";

const MilestoneItem = ({ register, onDeletePress, milestone, index }) => {
  return (
    <div className="flex flex-row space-x-2 items-end">
      <div className="mr-4">
        <label className="block text-sm font-medium text-gray-700">
          Referrals
        </label>

        <input
          type="number"
          min="0"
          step="1"
          required
          defaultValue={milestone.referralCount || 0}
          {...register(`programMilestones.${index}.referralCount`)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-14 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="flex-grow">
        <label className="block text-sm font-medium text-gray-700">
          Reward
        </label>

        <input
          type="text"
          required
          defaultValue={milestone.rewardDescription || ""}
          {...register(`programMilestones.${index}.rewardDescription`)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="flex-grow">
        <label className="block text-sm font-medium text-gray-700">
          Optional, promo code or redemption instructions
        </label>

        <input
          type="text"
          defaultValue={milestone.fulfillmentMessage || ""}
          {...register(`programMilestones.${index}.fulfillmentMessage`)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <button
        type="button"
        onClick={onDeletePress}
        className="inline-flex items-center p-2 border border-transparent rounded-full"
      >
        <TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
      </button>
    </div>
  );
};

const ReferralProgram = () => {
  const [state] = useContext(Context);
  const { handleSubmit, register, control, getValues, reset } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "programMilestones",
  });
  const { user, firestore: authFirestore, getNextPage } = useAuth();
  const [brandColor, setBrandColor] = useState(
    state.podcast?.brandColor || "#000000"
  );
  const [loading, setLoading] = useState(false);

  const generateDescriptionText = () => {
    const milestones = getValues("programMilestones");
    return milestones
      ?.filter((item) => item)
      .map((item) => {
        if (item.referralCount == 0) {
          return `By signing up: ${item.rewardDescription}`;
        }
        return `${item.referralCount} referrals: ${item.rewardDescription}`;
      })
      .join("<br />");
  };

  useEffect(() => {
    reset({
      ...getValues(),
      programMilestones: state.podcast?.programMilestones || [],
    });
    setBrandColor(state.podcast?.brandColor || "#000000");
  }, [state.podcast]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      console.log("form submiitted");

      const programDescriptionString = generateDescriptionText();
      const podRef = authFirestore.collection("podcasts").doc(state.podcast.id);
      console.log("program milestones: ", data.programMilestones);
      await podRef.update({
        ...data,
        programDescription: firebase.firestore.FieldValue.delete(),
        programDescriptionString,
        brandColor,
        programMilestones:
          (data.programMilestones || [])
            .filter((item) => item)
            .map((item) => {
              const copy = { ...item };
              copy.referralCount = parseInt(item.referralCount || 0);
              return copy;
            }) || [],
      });
      await getNextPage(user);
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(state.podcast.programDescriptionString);
  return (
    <form
      className="bg-white rounded-lg shadow overflow-hidden p-8 divide-y divide-gray-200"
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="text-sm text-gray-500 mb-8">
        {}
        Not sure what to run?{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.referomatic.fm/best-practices#design"
          className="text-blue-500 underline"
        >
          Check out this page for some ideas
        </a>{" "}
        or reach out to us if you have any questions or need help at{" "}
        <a href="mailto:support@refer.fm" className="text-blue-500 underline">
          support@refer.fm
        </a>
      </p>
      <div className="py-8">
        <div className="flex flex-row justify-between items-center text-lg font-medium text-gray-700">
          <div>Rewards</div>
          <button
            type="button"
            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <PlusIconOutline
              className="h-6 w-6"
              aria-hidden="true"
              onClick={() => {
                append({
                  referralCount: 0,
                  rewardDescription: "",
                  fulfillmentMessage: "",
                });
              }}
            />
          </button>
        </div>
        <p className="mt-1 mb-1 text-sm text-gray-500">
          Add rewards to trigger emails to both you and ambassadors of the
          program when they reach a certain number of referrals.
        </p>
        <div className="space-y-4">
          {fields.map((milestone, index) => {
            return (
              <MilestoneItem
                key={milestone.id}
                index={index}
                register={register}
                milestone={milestone}
                onDeletePress={() => {
                  console.log("delete clicked: ", index);
                  remove(index);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="pt-8">
        <div className="flex justify-center">
          <MyButton loading={loading} type="submit" text="Save" />
        </div>
      </div>
    </form>
  );
};

export default ReferralProgram;
