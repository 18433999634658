import axios from "axios";
import config from "../constants/config";
import { useAuth } from "../hooks/useAuth";

const networking = axios.create({
  baseURL: config.apiUrl,
  responseType: "json",
});

const useNetworking = () => {
  const auth = useAuth();

  networking.interceptors.request.use(async (req) => {
    const token = await auth.user?.getIdToken();
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  });
  return networking;
};

const parseError = (e) => {
  if (e.response) {
    return `Error: ${e.response.data.error}`;
  }
  return e.error;
};

export { useNetworking, parseError };
export default networking;
