import { ExternalLinkIcon } from "@heroicons/react/solid";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../cache";
import MyFullSizeSpinner from "../components/MyFullSizeSpinner";
import { HOME } from "../constants/routes";
import { useAuth } from "../hooks/useAuth";
import BasicInfo from "./BasicInfo";
import Nav from "./Nav";
import ReferralProgram from "./ReferralProgram";

const Home = () => {
  const [state] = useContext(Context);
  const { user, getNextPage } = useAuth();
  const history = useHistory();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabs = [{ name: "Basic Info" }, { name: "Referral Program" }];
  const renderTabs = () => {
    return (
      <div className="block mb-4">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              onClick={() => {
                setCurrentTabIndex(tabIdx);
              }}
              className={cx(
                tabIdx === currentTabIndex
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
            >
              <span className="inline-flex items-center">{tab.name}</span>
              <span
                aria-hidden="true"
                className={cx(
                  tabIdx === currentTabIndex ? "bg-blue-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </nav>
      </div>
    );
  };

  useEffect(() => {
    const run = async () => {
      console.log("running 0");
      const nextPage = await getNextPage(user);
      if (nextPage !== HOME) {
        history.push(nextPage);
      }
    };
    run();
  }, []);

  if (!state.podcast) {
    return <MyFullSizeSpinner />;
  }

  const link = `https://refer.fm/${state.podcast.slug}`;
  return (
    <div className="bg-gray-100">
      <Nav showAllLinks podcast={state.podcast} />
      <div className="max-w-screen-2xl mx-auto py-8 pb-6 px-4 lg:pb-16">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            {renderTabs()}
            {currentTabIndex === 0 ? <BasicInfo /> : <ReferralProgram />}
          </div>
          <div
            className="w-full bg-white rounded-lg shadow overflow-hidden p-4 flex flex-col"
            style={{ height: 1000 }}
          >
            <div className="flex flex-row justify-between items-center  mb-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Preview
              </h3>
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Visit
                <ExternalLinkIcon
                  className="-mr-1 ml-3 h-5 w-5"
                  aria-hidden="true"
                />
              </a>
            </div>
            <div className="flex-grow">
              <iframe
                title="preview"
                key={JSON.stringify(state.podcast)}
                style={{
                  pointerEvents: "none",
                }}
                src={link}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
