import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, withRouter } from "react-router-dom";
import { compose } from "recompose";
import BannerError from "../components/BannerError";
import MyButton from "../components/MyButton";
import { FORGOT_PASSWORD, HOME, REGISTER } from "../constants/routes";
import { useAuth } from "../hooks/useAuth";
import { useNetworking } from "../utils/networking";

const SignInPage = (props) => {
  const history = useHistory();
  const networking = useNetworking();
  const {
    handleSubmit,
    register,
    setError,
    formState,
    getValues,
    clearErrors,
  } = useForm();
  const { errors } = formState;
  const { user, signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      history.push(HOME);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const nextPage = await signIn(data.email, data.password);

      history.push(nextPage);
    } catch (error) {
      setError("auth", {
        type: "manual",
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        <div>
          <img
            className="mx-auto h-24 w-auto"
            src="/robot.svg"
            alt="Workflow"
          />

          <h1 class="text-center mt-8 mb-2 text-gray-900 font-semibold">
            Welcome back! Log in to your account.
          </h1>
        </div>
        <form
          className="mt-8 space-y-4"
          onSubmit={(e) => {
            clearErrors();
            handleSubmit(onSubmit)(e);
          }}
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <input
                {...register("email", { required: true })}
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
          </div>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <input
                {...register("password", { required: true })}
                type="password"
                autoComplete="password"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>
          {errors.auth && <BannerError text={errors.auth.message} />}

          <div className="pt-2">
            <MyButton
              type="submit"
              text="Sign in to account"
              loading={loading}
            />
          </div>

          <div className="flex flex-row justify-center pt-6 space-x-8">
            <Link className="text-sm hover:text-gray-900" to={REGISTER}>
              Create new account
            </Link>
            <Link className="text-sm hover:text-gray-900" to={FORGOT_PASSWORD}>
              Forgot password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default compose(withRouter)(SignInPage);
