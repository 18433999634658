import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "recompose";
import BannerError from "../components/BannerError";
import BannerSuccess from "../components/BannerSuccess";
import MyButton from "../components/MyButton";
import { HOME } from "../constants/routes";
import { useAuth } from "../hooks/useAuth";
import { useNetworking } from "../utils/networking";

const ForgotPassword = (props) => {
  const history = useHistory();
  const networking = useNetworking();
  const {
    handleSubmit,
    register,
    setError,
    formState,
    getValues,
    clearErrors,
  } = useForm();
  const { errors } = formState;
  const { user, forgotPassword } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      history.push(HOME);
    }
  }, []);

  const onSubmit = async (data) => {
    console.log("form submitted");
    try {
      setLoading(true);
      await forgotPassword(data.email);
    } catch (error) {
      setError("auth", {
        type: "manual",
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        <div>
          <img
            className="mx-auto h-24 w-auto"
            src="/robot.svg"
            alt="Workflow"
          />
          <h1 class="text-center mt-8 mb-2 text-gray-900 font-semibold">
            Reset your password
          </h1>
          <p class="text-center text-sm mb-10">
            Enter your email and we'll send you a link to reset your password.
          </p>
        </div>
        {formState.isSubmitSuccessful ? (
          <BannerSuccess
            text={`We just sent an email to ${getValues(
              "email"
            )}. Please click on the link in your email to set a new password.`}
          />
        ) : (
          <form
            className="mt-8 space-y-4"
            onSubmit={(e) => {
              clearErrors();
              handleSubmit(onSubmit)(e);
            }}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  {...register("email", { required: true })}
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
            </div>
            {errors.auth && <BannerError text={errors.auth.message} />}

            <div className="pt-2">
              <MyButton
                type="submit"
                text="Reset your password"
                loading={loading}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default compose(withRouter)(ForgotPassword);
