import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BannerError from "../components/BannerError";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import { HOME, SEARCH } from "../constants/routes";
import { useAuth } from "../hooks/useAuth";
import { useNetworking } from "../utils/networking";
import Nav from "./Nav";

const Search = () => {
  const history = useHistory();
  const networking = useNetworking();
  const { getNextPage, user } = useAuth();
  const { handleSubmit, register, setError, formState } = useForm();
  const { errors } = formState;
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [selectedPodcast, setSelectedPodcast] = useState(null);

  const onSubmit = async (data) => {
    try {
      setSearchLoading(true);
      console.log("form submiitted");
      const result = await networking.post("/search", {
        term: data.searchTerm,
      });
      setSearchResults(result.data.podcasts || []);
    } catch (error) {
      setError("searchTerm", {
        type: "manual",
        message: JSON.stringify(error),
      });
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const run = async () => {
      const nextPage = await getNextPage(user);
      if (nextPage !== SEARCH) {
        history.push(nextPage);
      }
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      if (selectedPodcast === null) {
        return;
      }
      try {
        await networking.post("/add-podcast", {
          podcast: selectedPodcast,
        });
        history.push(HOME);
      } catch (e) {
        window.alert(e);
        setSelectedPodcast(null);
      }
    };
    run();
  }, [selectedPodcast, history]);

  const renderPodcastSelectButton = (searchItem) => {
    if (selectedPodcast === null) {
      return (
        <button
          className="text-blue-600 hover:text-blue-900"
          onClick={() => {
            setSelectedPodcast(searchItem);
          }}
        >
          Select
        </button>
      );
    } else if (selectedPodcast.feedUrl === searchItem.feedUrl) {
      return (
        <div className="flex flex-row justify-end">
          <MySpinner />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderResults = () => {
    if (searchResults === null) {
      return <></>;
    } else if (searchResults === []) {
      return (
        <BannerError text="No podcasts found, double check your search terms and try again or contact us at support@refer.fm" />
      );
    }

    return (
      <div className="max-w-3xl w-full p-2 overflow-hidden">
        <table className="shadow rounded-lg overflow-hidden w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {searchResults.map((searchResult) => (
              <tr key={searchResult.artworkUrl100}>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={searchResult.artworkUrl100}
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {searchResult.trackName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {searchResult.artistName}
                      </div>
                    </div>
                  </div>
                </td>

                <td className="px-6 py-4 text-right text-sm font-medium">
                  {renderPodcastSelectButton(searchResult)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Nav />
      <div className="w-full flex items-center flex-col flex-grow py-12 px-4 sm:px-6 lg:px-8 space-y-8 ">
        <div>
          <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-900">
            Add your podcast to get started
          </h2>
          <p className="mt-2 text-center text-md text-gray-600">
            We'll automatically build out a customized referral page for you,
            using your podcast information.
          </p>
        </div>
        <form
          className="w-full mt-4 sm:flex sm:max-w-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            {...register("searchTerm")}
            required
            className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:placeholder-gray-400"
            placeholder="Search for your podcast by name"
          />
          <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <MyButton type="submit" text="Search" loading={searchLoading} />
          </div>
        </form>

        {errors.searchTerm && (
          <BannerError text={JSON.stringify(errors.searchTerm)} />
        )}

        {renderResults()}
      </div>
    </div>
  );
};

export default Search;
