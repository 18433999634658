import * as Actions from "./actions";

const Reducer = (state, action) => {
  switch (action.type) {
    case Actions.ACTION_PODCAST_SET:
      return { ...state, podcast: action.payload };
    case Actions.ACTION_USER_SET:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
export default Reducer;
