import React from "react";

// const useQuery = () => {
// return new URLSearchParams(useLocation().search);
// };
const UpgradeBanner = () => {
  // const networking = useNetworking();
  // const query = useQuery();
  // const [state] = useContext(Context);
  // const [loading, setLoading] = useState(false);

  return <></>;
  // if (
  //   !state.podcast ||
  //   !state.podcast.createdAt ||
  //   state.user.upgraded ||
  //   query.session_id
  // ) {
  //   return <></>;
  // }

  // const getDaysRemaining = () => {
  //   var start = moment();
  //   var trialLength = 14;
  //   var end = moment(state.podcast.createdAt.toDate()).add(trialLength, "days");

  //   var days = end.diff(start, "days");
  //   if (days > 14) {
  //     days = 14;
  //   } else if (days < 0) {
  //     days = 0;
  //   }

  //   if (days <= 0) {
  //     return null;
  //   }
  //   return `${days} ${days === 1 ? "day" : "days"}`;
  // };

  // const daysRemaining = getDaysRemaining();
  // return (
  //   <>
  //     <div className="relative bg-blue-900">
  //       <div className="max-w-7xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
  //         <div className="sm:text-center sm:px-16">
  //           {loading ? (
  //             <span className="text-blue-200">Loading...</span>
  //           ) : (
  //             <button
  //               onClick={async () => {
  //                 try {
  //                   setLoading(true);
  //                   const response = await networking.get(
  //                     "/stripe/subscribe-link"
  //                   );
  //                   const link = response.data.link;
  //                   console.log("got link: ", link);
  //                   window.location.href = link;
  //                 } catch (e) {
  //                   console.log(e);
  //                 } finally {
  //                   setLoading(false);
  //                 }
  //               }}
  //             >
  //               <p className="font-medium text-blue-200">
  //                 <span>
  //                   {daysRemaining === null ? (
  //                     <>Your free trial is over</>
  //                   ) : (
  //                     <>
  //                       You have&nbsp;
  //                       <span className="font-bold">{daysRemaining}</span>
  //                       &nbsp;left in your free trial
  //                     </>
  //                   )}
  //                   <> – Upgrade your account&nbsp;&rarr;</>
  //                 </span>
  //               </p>
  //             </button>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default UpgradeBanner;
