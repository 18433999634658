import { default as React, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../cache";
import MyFullSizeSpinner from "../components/MyFullSizeSpinner";
import config from "../constants/config";
import { HOME } from "../constants/routes";
import { useAuth } from "../hooks/useAuth";
import Nav from "./Nav";

const Analytics = () => {
  const [state] = useContext(Context);
  const { user, getNextPage } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const run = async () => {
      console.log("running 0");
      const nextPage = await getNextPage(user);
      if (nextPage !== HOME) {
        history.push(nextPage);
      }
    };
    run();
  }, []);

  if (!state.podcast) {
    return <MyFullSizeSpinner />;
  }

  console.log("redraw");
  return (
    <div className="bg-gray-100">
      <Nav showAllLinks podcast={state.podcast} />
      <div className="py-8 pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div className="bg-white rounded-lg shadow overflow-hidden p-8">
          <iframe
            title="Analytics"
            className="min-h-screen w-full"
            src={`${config.baseUrl}/dashboard/${state.podcast.slug}?secret=${state.podcast.secret}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
