import cx from "classnames";
import React from "react";

const MyButton = ({ loading, text, ...props }) => {
  return (
    <button
      {...props}
      disabled={loading}
      className={cx(
        "w-full bg-blue-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
        { "cursor-wait": loading }
      )}
    >
      <div>{text}</div>
      <div>
        {loading ? (
          <svg
            x-show="spin"
            className="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : null}
      </div>
    </button>
  );
};

export default MyButton;
