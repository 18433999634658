import React from "react";
import { Link } from "react-router-dom";
import UpgradeBanner from "../components/UpgradeBanner";
import * as ROUTES from "../constants/routes";
import { useAuth } from "../hooks/useAuth";

const Nav = ({ showAllLinks, podcast }) => {
  const { logout } = useAuth();

  return (
    <>
      <header className="bg-white shadow-md">
        <nav
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          aria-label="Top"
        >
          <div className="w-full py-4 flex items-center justify-between">
            <div className="flex items-center">
              <a href="/" className="flex flex-row items-center">
                <img className="h-10 w-auto mr-4" src="/robot.svg" alt="logo" />
                <h2 className="text-2xl font-overpass font-bold text-gray-900">
                  Refer-O-Matic
                </h2>
              </a>
            </div>
            <div className="ml-10 space-x-8">
              {showAllLinks ? (
                <>
                  <Link
                    className="inline-block text-base font-medium py-2 text-gray-500 hover:text-gray-900"
                    to={ROUTES.HOME}
                  >
                    Home
                  </Link>
                  <Link
                    className="inline-block text-base font-medium py-2 text-gray-500 hover:text-gray-900"
                    to={ROUTES.ANALYTICS}
                  >
                    Analytics
                  </Link>
                </>
              ) : null}
              <button
                className="inline-block text-base font-medium py-2 text-gray-500 hover:text-gray-900"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </nav>
      </header>
      {showAllLinks ? <UpgradeBanner /> : null}
    </>
  );
};

export default Nav;
