import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Context } from "../cache";
import { ACTION_PODCAST_SET, ACTION_USER_SET } from "../cache/actions";
import config from "../constants/config";
import * as ROUTES from "../constants/routes";
import networking from "../utils/networking";

// Initialize Firebase
firebase.initializeApp(config.firebase);
const firestore = firebase.firestore();

const AuthContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [, dispatch] = useContext(Context);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const signInWithEmailLink = async (email, code) => {
    const result = await firebase.auth().signInWithEmailLink(email, code);
    const token = result.user && (await result.user.getIdToken());
    networking.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setUser(result.user);
    return await getNextPage(result.user);
  };

  const forgotPassword = async (email) => {
    try {
      const result = await firebase.auth().sendPasswordResetEmail(email);
    } catch (e) {
      throw new Error(
        "We could not find an account associated with that email address."
      );
    }
  };

  const signUp = async (email, password) => {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const token = result.user && (await result.user.getIdToken());
    networking.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setUser(result.user);
    return {
      userId: result.user.uid,
      nextPage: await getNextPage(result.user),
    };
  };

  const signIn = async (email, password) => {
    const result = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const token = result.user && (await result.user.getIdToken());
    networking.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setUser(result.user);
    return await getNextPage(result.user);
  };

  const logout = async () => {
    await firebase.auth().signOut();
    setUser(null);
  };

  const getNextPage = async (user) => {
    console.log("running getNextPage");
    const dbQuery = await firestore
      .collection("users")
      .where("email", "==", user.email)
      .limit(1)
      .get();

    if (dbQuery.empty) {
      console.log("no users found");
      dispatch({
        type: ACTION_USER_SET,
        payload: null,
      });
      return ROUTES.SEARCH;
    }

    const userDoc = dbQuery.docs[0];
    dispatch({
      type: ACTION_USER_SET,
      payload: userDoc.data(),
    });

    const dbId = userDoc.id;

    const result = await firestore
      .collection("podcasts")
      .where("owner", "==", dbId)
      .get();
    if (result.empty) {
      dispatch({
        type: ACTION_PODCAST_SET,
        payload: null,
      });
      return ROUTES.SEARCH;
    } else {
      const podcast = result.docs[0].data();
      podcast.secret = userDoc.data().secret;
      podcast.id = result.docs[0].ref.id;
      dispatch({
        type: ACTION_PODCAST_SET,
        payload: podcast,
      });
      return ROUTES.HOME;
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setUser(user);
      setIsAuthenticating(false);
      const token = user && (await user.getIdToken());
      networking.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const values = {
    user,
    isAuthenticating,
    getNextPage,
    signInWithEmailLink,
    signIn,
    signUp,
    logout,
    forgotPassword,
    firestore,
  };

  return (
    <AuthContext.Provider value={values}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};
