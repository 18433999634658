import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ForgotPassword from "./auth/ForgotPassword";
import Register from "./auth/Register";
import SignInPage from "./auth/SignIn";
import * as ROUTES from "./constants/routes";
import Analytics from "./dashboard/Analytics";
import Home from "./dashboard/Home";
import Search from "./dashboard/Search";
import PrivateRoute from "./route/PrivateRoute";

const App = () => (
  <div className="font-ptsans">
    <Router>
      <Switch>
        <PrivateRoute exact path={ROUTES.HOME} component={Home} />
        <PrivateRoute path={ROUTES.SEARCH} component={Search} />
        <PrivateRoute path={ROUTES.ANALYTICS} component={Analytics} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.REGISTER} component={Register} />
        <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      </Switch>
    </Router>
  </div>
);

export default App;
