const prodConfig = {
  firebase: {
    apiKey: "AIzaSyDHQBpxZQMrVQuROK0wGnAf_eSMhjvhSvI",
    authDomain: "referfm-production.firebaseapp.com",
    projectId: "referfm-production",
    storageBucket: "referfm-production.appspot.com",
    messagingSenderId: "931256098555",
    appId: "1:931256098555:web:021cb2c44971aa2ae71a5e",
  },
  baseUrl: "https://refer.fm",
  redirectUrl: "https://dashboard.refer.fm/confirm",
  apiUrl: "https://referfm-production.uk.r.appspot.com",
};

const stagingConfig = {
  firebase: {
    apiKey: "AIzaSyDhMu9UwFrBKhMekNphQwk-MA0gsXOyS-4",
    authDomain: "referfm-staging.firebaseapp.com",
    projectId: "referfm-staging",
    storageBucket: "referfm-staging.appspot.com",
    messagingSenderId: "555457037057",
    appId: "1:555457037057:web:a67ec980e8419733d49100",
  },
  baseUrl: "https://staging.refer.fm",
  redirectUrl: "https://staging-dashboard.refer.fm/confirm",
  apiUrl: "https://referfm-staging.uk.r.appspot.com",
};

const devConfig = {
  firebase: {
    apiKey: "AIzaSyDhMu9UwFrBKhMekNphQwk-MA0gsXOyS-4",
    authDomain: "referfm-staging.firebaseapp.com",
    projectId: "referfm-staging",
    storageBucket: "referfm-staging.appspot.com",
    messagingSenderId: "555457037057",
    appId: "1:555457037057:web:a67ec980e8419733d49100",
  },
  baseUrl: "https://staging.refer.fm",
  redirectUrl: "http://localhost:3003/confirm",
  apiUrl: "https://referfm-server.ngrok.io",
};

console.log("env: ", process.env.REACT_APP_ENV);

let config = prodConfig;
if (process.env.REACT_APP_ENV === "staging") {
  config = stagingConfig;
} else if (process.env.REACT_APP_ENV === "dev") {
  config = devConfig;
}

export default config;
